<template>
  <div>
    <visualiza-dados
      tituloListagem="Contratos"
      :cabecalhos_colunas="cabecalhos"
      :id_registro="chaveRegistro"
      :apiConsultarRegistros="apiConsultar"
      :apiExcluirRegistro="apiExcluir"
      :coluna_ordenar="colunaOrdenar"
      :formataColuna="formatarColuna"
      :pode_imprimir="true"
      :filtros="filtros"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
      @imprimirItem="imprimirItem"
    >
      <template v-slot:filtros>
        <v-row class="mx-1">
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-text-field
              v-model="filtros.numero"
              label="Número contrato"
              clearable
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-autocomplete
              label="Faixa do contrato"
              v-model="filtros.faixas"
              :items="faixaContratosList"
              item-value="idfaixacontrato"
              item-text="descricao"
              color="#94896d"
              autofocus
              multiple
              clearable
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-select
              v-model="filtros.somenteAtivos"
              :items="statusContratosList"
              item-value="valor"
              item-text="descricao"
              color="blue"
              solo
            />
          </v-col>
        </v-row>
      </template>
    </visualiza-dados>
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import geradorPDF from '@/mixins/geradorPDF';
import {getApi, excluirApi} from '@/network/ApiService';
import {api} from '@/network/Api';
export default {
  components: {
    VisualizaDados,
  },
  mixins: [geradorPDF],
  data() {
    return {
      cabecalhos: [
        {
          text: 'Número',
          value: 'contrato',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Titular',
          value: 'titular',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Faixa',
          value: 'faixa',
          sortable: true,
          filterable: true,
        },
        // {
        //   text: 'Cobrador',
        //   value: 'utiliza_cobrador',
        //   sortable: true,
        //   filterable: true
        // },
        {
          text: 'Assinatura',
          value: 'data_assinatura',
          sortable: true,
          filterable: true,
        },

        {
          text: 'Status',
          value: 'status',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      colunaOrdenar: 'numero_contrato',
      apiConsultar: api.contratoListarTodos,
      apiExcluir: api.contratoExcluir,
      apiImprimir: api.contratoImprimir,
      chaveRegistro: 'idcontrato',
      faixaContratosList: [],
      statusContratosList: [
        {valor: true, descricao: 'Exibir somente contratos ativos'},
        {valor: false, descricao: 'Exibir todos os contratos'},
      ],
      filtros: {
        somenteAtivos: true,
        numero: '',
        faixas: [],
      },
    };
  },
  created() {
    getApi(api.faixaContratoListarTodas)
      .then((res) => {
        this.faixaContratosList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    cadastrarItem() {
      this.$router.push({name: 'ContratoInsert'});
    },
    editarItem(params) {
      this.$router.push({name: 'ContratoEdit', params: {id: params.id}});
    },
    formataData(valor, coluna) {
      let dataOriginal = valor[coluna.value];
      if (dataOriginal == null || dataOriginal == undefined) {
        return '-';
      }

      const partesData = dataOriginal.split('-');
      if (partesData.length === 3) {
        const dia = partesData[2];
        const mes = partesData[1];
        const ano = partesData[0];

        return `${dia}/${mes}/${ano}`;
      }
      return dataOriginal;
    },
    formatarColuna(item, column) {
      if (column.value === 'data_assinatura') {
        return this.formataData(item, column);
      }

      // Se não for a coluna 'ativo', use o valor padrão
      return item[column.value];
    },

    async imprimirItem(params) {
      await this.abirPDF(this.apiImprimir, params.id);
      // await this.gerarPDF(this.apiImprimir, params.id, 'contrato_' + params.dados.titular);
    },
  },
};
</script>

<style scoped></style>
