<template>
  <div>
    <v-card v-show="!showProgresso" class="mx-4 my-12" style="background-color:#f8f9fa">
      <v-card-title style="margin-bottom: 45px;">
        <v-row>
          <v-col cols="9">
            <h5 style="color: #94896d;">{{ tituloListagem }}</h5>
            <v-divider />
          </v-col>
          <v-col cols="3" style="text-align:end;">
            <v-btn v-show="mostra_botao_cadastrar" color="#990000" @click="cadastrar">
              <span style="color: #ffffff">Cadastrar</span>
            </v-btn>
          </v-col>
        </v-row> 
      </v-card-title>

      <v-card-title style="margin-top:-20px; margin-bottom:25px;"> 
          <v-row style="display: flex;">
            <v-col cols="12">
              <v-select
                :items="listaAnos"
                v-model="anoSelecionado"
                label="Ano"
                item-text="ano"
                item-value="ano"
                color="#94896d"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                dense
                color="#FFFFFF"
                prepend-inner-icon="mdi-magnify"
                label="Digite aqui para pesquisar"
                style="background-color: #f5f4ec"
                clearable
                solo-inverted
                hide-details
              />
            </v-col>
          </v-row>
      </v-card-title>

      <!-- Tabela para exibir os registros cadastrados -->
      <v-data-table
        :items-per-page="100"
        :footer-props="{itemsPerPageOptions:[25, 50, 100, 200, -1]}"
        :headers="cabecalhos_colunas"
        :items="registrosFiltrados"
        :search="search"
        :sort-by="coluna_ordenar"
        group-by="ano"
        class="elevation-1"
        style="background-color: #f8f9fa"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="column in cabecalhos_colunas" :key="column.value">
              <div v-if="column.value != 'actions'">
                {{ formataColuna(item, column) }}
              </div>
              <div v-if="column.value == 'actions'" style="text-align: end;">
                <router-link v-if="pode_editar" :to="{ name: nome_rota_formulario_edicao, params: { id: item[id_registro] } }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </router-link>

                <v-tooltip v-if="pode_excluir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="deleteItem(item)" color="#344767" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:[`top`]>
          <v-dialog v-model="dialogDelete" max-width="555px">
            <v-card>
              <v-card-title class="text-h6">Tem certeza que deseja excluir este registro?</v-card-title>
                <v-card-actions>
                <v-spacer />

                <v-btn color="#f5f4ec" @click="dialogDelete = false">
                  <span style="color:#990000">Não</span>
                </v-btn>

                <v-btn color="#990000" @click="deleteItemConfirm">
                  <span style="color:#FFFFFF">Sim</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde" />
    <dialog-msg-alerta tituloDialogMsgAlerta="Atenção!" :mensagemAlerta="mensagemAlerta" :showMensagem="dialogMensagemALerta" />
  </div>
</template>

<script>
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import {excluirApi, getApi} from '@/network/ApiService';

export default {
  components: {
    DialogMsgAlerta,
  },
  props: {
    cabecalhos_colunas: {
      type: Array,
      required: true,
    },
    coluna_ordenar: {
      type: String,
      required: false,
    },
    id_registro: {
      type: String,
      required: true,
    },
    tituloListagem: {
      type: String,
      required: false,
    },
    apiConsultarRegistros: {
      type: String,
      required: true,
    },
    apiExcluirRegistro: {
      type: String,
      required: false,
    },
    nome_rota_formulario_insercao: {
      type: String,
      required: false,
    },
    nome_rota_formulario_edicao: {
      type: String,
      required: false,
    },
    formataColuna: {
      type: Function,
      default: (item, column) => item[column.value],
    },
    pode_editar: {
      type: Boolean,
      default: true,
    },
    pode_excluir: {
      type: Boolean,
      default: true,
    },
    mostra_botao_cadastrar: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.initialize();
  },

  data() {
    return {
      dialogMensagemALerta: false,
      mensagemAlerta: '',
      search: '',
      dialog: false,
      dialogDelete: false,
      registros: [],
      primarykey: -1,
      showProgresso: false,
      total_registros: 0,
      anoSelecionado: '',
      listaAnos: [],
    };
  },

  methods: {
    initialize() {
      this.configuraFiltroAno();
      this.showProgresso = true;
      getApi(this.apiConsultarRegistros)
        .then((res) => {
          this.registros = res.data;
          this.total_registros = this.registros.length;
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        })
        .catch((err) => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
          console.log(err);
        });
    },
    configuraFiltroAno() {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      this.anoSelecionado = 'Todos';

      var anos = ['Todos'];

      // Adicionar -10 / atual / +5 anos
      for (var i = anoAtual - 2; i < anoAtual; i++) {
        anos.push(i);
      }
      anos.push(anoAtual);
      for (var j = anoAtual + 1; j <= anoAtual + 2; j++) {
        anos.push(j);
      }

      this.listaAnos = anos;
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.primarykey = this.editedItem[this.id_registro];
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch((err) => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta =
              'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.primarykey = -1;
      this.clearForm();
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.initialize();
      this.mostra_botao_cadastrar = true;
    },
    cadastrar() {
      this.$router.push({name: this.nome_rota_formulario_insercao});
      // this.$router.push({name: 'UsuarioInsert'});
      // this.$router.push({name: 'UsuarioEdit'});
    },
  },
  computed: {
    registrosFiltrados() {
      if (this.anoSelecionado == 'Todos') {
        return this.registros;
      }
      return this.registros.filter((item) => {
        return item.ano == this.anoSelecionado;
      });
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.centralizado {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Isso é opcional, dependendo do seu layout */
}

.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>