<template>
  <div>
    <visualiza-dados
      :cabecalhos_colunas="cabecalhos_colunas"
      :id_registro="id_registro"
      :tituloListagem="tituloListagem"
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="coluna_ordenar"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
      :formataColuna="formatarColuna"
      :pode_excluir="false" />
  </div>
</template>

<script>
  import VisualizaDados from '@/components/crud/VisualizaDados.vue';
  import {api} from '@/network/Api';

  export default {
    components: {
      VisualizaDados,
    },
    data() {
      return {
        tituloListagem: 'Lista de usuários',
        coluna_ordenar: 'nome',
        apiConsultarRegistros: api.usuarioListarTodos,
        apiExcluirRegistro: api.usuarioExcluir,
        id_registro: 'id',

        cabecalhos_colunas: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            sortable: true,
            filterable: true,
          },
          {
            text: 'E-mail',
            value: 'email',
            align: 'start',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Perfil',
            value: 'perfil',
            align: 'start',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Ativo',
            value: 'ativo',
            align: 'start',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Ações',
            value: 'actions',
            align: 'end',
            sortable: false,
          },
        ],
      };
    },
    methods: {
      formatarColuna(item, column) {
        if (column.value === 'ativo') {
          return item.ativo === 1 ? 'Sim' : 'Não';
        }
        if (column.value === 'perfil') {
            if(item.perfil != null) {
                return item.perfil.descricao;
            }
            return '-';        
        }

        // Se não for a coluna 'ativo', use o valor padrão
        return item[column.value];
      },
      cadastrarItem() {
        this.$router.push({name: 'UsuarioInsert'});
      },
      editarItem(params) {
        this.$router.push({name: 'UsuarioEdit', params: {id: params.id}});
      },
    },
  };
</script>

<style scoped></style>
