<template>
  <div>
    <edita-dados-v2
      :idregistro="idregistro"
      :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro"
      :campos_formulario="dados"
      :showProgresso="showProgresso"
      @saveCompleted="saveCompleted"
      @close="close"
    >
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="dados.numero_pagamento"
            type="text"
            label="Pagamento"
            color="#94896d"
            disabled
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="dados.descricao"
            label="Descrição"
            type="text"
            color="#94896d"
            autofocus
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="dados.idpessoa"
            :items="listaPessoas"
            label="Membro"
            item-value="idpessoa"
            item-text="nome"
            color="#94896d"
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :items="listaTipoPagamento"
            v-model="dados.tipo"
            label="Tipo do pagamento"
            item-value="valor"
            item-text="descricao"
            color="#94896d"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="dados.idformapagamento"
            :items="listaFormasPagamento"
            label="Forma de pagamento:"
            item-value="idformapagamento"
            item-text="descricao"
            color="#94896d"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <span class="text-field-money-title">Valor</span>
          <money
            class="text-field-money"
            v-model="dados.valor"
            v-bind="money"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="dados.numero_parcelas"
            type="text"
            label="Parcela"
            color="#94896d"
            autofocus
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="dados.parcela_final"
            type="text"
            label="Parcela Final"
            color="#94896d"
            autofocus
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="dados.documento"
            type="text"
            label="Documento"
            color="#94896d"
            autofocus
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="dados.numero_serie"
            type="text"
            label="Número de Série"
            color="#94896d"
            autofocus
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-select
            :items="listaContas"
            v-model="dados.idconta"
            label="Conta"
            item-value="idconta"
            item-text="descricao"
            color="#94896d"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="dados.idcaixa"
            :items="listaCaixas"
            label="Caixa"
            item-value="idcaixa"
            item-text="descricao"
            color="#94896d"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <CampoData
            titulo="Data Movimentacao"
            v-model="dados.data_movimentacao"
          />
        </v-col>
        <v-col cols="4">
          <CampoData
            titulo="Data Pagamento"
            v-model="dados.data_pagamento"
          />
        </v-col>
        <v-col cols="4">
          <CampoData
            titulo="Data Vencimento"
            v-model="dados.data_vencimento"
          />
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="4">
          <v-text-field type="text" label="Desconto:" v-model="dados.desconto" autofocus color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-text-field type="text" label="Acréscimo:" v-model="dados.acrescimo" autofocus color="#94896d" dense />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field type="text" label="* Observação:" v-model="dados.observacao" autofocus color="#94896d" dense />
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="4">
          <v-text-field
            disabled
            type="text"
            label="Valor Original"
            v-model="dados.valor_original"
            autofocus
            color="#94896d"
            dense
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            disabled
            type="text"
            label="Valor Efetivo"
            v-model="dados.valor_efetivo"
            autofocus
            color="#94896d"
            dense
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            disabled
            type="text"
            label="Valor Restante"
            v-model="dados.valor_restante"
            autofocus
            color="#94896d"
            dense
          />
        </v-col>
      </v-row>
      
      <template #acoes>
          <v-btn v-if="dados.status_pagamento != 'CP'" color="#5bdb5b" @click="confirmarPagamento">
            <span style="color: #FFFFFF">Pagar</span>
          </v-btn>
      </template>
      
      
    </edita-dados-v2>
  </div>
</template>
<script>
import mixin from '@/store/mixin.js';
import EditaDadosV2 from '@/components/crud/EditaDadosV2.vue';
import {format, parseISO} from 'date-fns';
import {api} from '@/network/Api';
import {getApi} from '@/network/ApiService';
  import geradorPDF from '@/mixins/geradorPDF';

export default {
  components: {
    EditaDadosV2,
  },
  created() {
    this.initialize();
  },
  mixins: [mixin, geradorPDF],
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de Pagamento',
      price: 0,
      url_consultar_dados: api.pagamentoConsultar,
      apiAtualizarRegistro: api.pagamentoEditar,
      nome_rota_listagem_dados: 'Pagamentos',
      listaContas: [],
      listaFormasPagamento: [],
      listaContratos: [],
      listaCaixas: [],
      dados: {
        idpagamento: '',
        idformapagamento: '',
        descricao: '',
        valor: 0,
        tipo: '',
        data_movimentacao: format(
          parseISO(new Date().toISOString()),
          'yyyy-MM-dd'
        ),
        idconta: '',
        idcaixa: '',
        idcontrato: '',
        numero_parcelas: '',
        pc_desconto: '',
        pc_acrescimo: '',
        desconto: '',
        acrescimo: '',
        observacao: '',
        data_vencimento: format(
          parseISO(new Date().toISOString()),
          'yyyy-MM-dd'
        ),
        data_pagamento: format(
          parseISO(new Date().toISOString()),
          'yyyy-MM-dd'
        ),
        data_entrada: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        data_programado: format(
          parseISO(new Date().toISOString()),
          'yyyy-MM-dd'
        ),
        valor_efetivo: '',
        numero_pagamento: '',
        valor_restante: '',
        idtransferencia: '',
        documento: '',
        numero_serie: '',
        valor_original: '',
        parcela_final: '',
        idcontratorenovacao: '',
        idpessoa: '',
        status_pagamento: '',
      },
      showProgresso: false,
      listaFormasPagamento: [],
      listaContas: [],
      listaCaixas: [],
      listaContratos: [],
      listaTipoPagamento: [
        {valor: 'E', descricao: 'Entrada'},
        {valor: 'S', descricao: 'Saída'},
      ],
      listaTransferencias: [],
      listaContratoRenovacao: [],
      listaPessoas: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },

  methods: {
    async initialize() {
      try {
        this.showProgresso = true;

        let consultaCaixa = await getApi(api.caixaListarTodas);
        let consultaContas = await getApi(api.contaListarTodas);
        let consultaFormasPagamento = await getApi(
          api.formaPagamentoListarTodas
        );
        let consultaContratos = await getApi(api.contratoListarTodos);
        let currentData = await getApi(
          this.url_consultar_dados + `/${this.idregistro}`
        );
        let consultaPessoa = await getApi(api.pessoaListarTodas);

        this.listaCaixas = consultaCaixa.data;
        this.listaContas = consultaContas.data;
        this.listaFormasPagamento = consultaFormasPagamento.data;
        this.listaContratos = consultaContratos.data;
        this.listaPessoas = consultaPessoa.data;

        this.dados.idpagamento = currentData.data.idpagamento;
        this.dados.acrescimo = currentData.data.acrescimo;
        this.dados.created_at = currentData.data.created_at;
        this.dados.data_entrada = currentData.data.data_entrada;
        this.dados.data_movimentacao = currentData.data.data_movimentacao;
        this.dados.data_pagamento = currentData.data.data_pagamento;
        this.dados.data_programado = currentData.data.data_programado;
        this.dados.data_vencimento = currentData.data.data_vencimento;
        this.dados.deleted_at = currentData.data.deleted_at;
        this.dados.desconto = currentData.data.desconto;
        this.dados.descricao = currentData.data.descricao;
        this.dados.documento = currentData.data.documento;
        this.dados.idcaixa = currentData.data.idcaixa;
        this.dados.idconta = currentData.data.idconta;
        this.dados.idcontrato = currentData.data.idcontrato;
        this.dados.idcontratorenovacao = currentData.data.idcontratorenovacao;
        this.dados.idformapagamento = currentData.data.idformapagamento;

        this.dados.idpessoa = currentData.data.idpessoa;
        this.dados.idtransferencia = currentData.data.idtransferencia;
        this.dados.numero_pagamento = currentData.data.numero_pagamento;
        this.dados.numero_parcelas = currentData.data.numero_parcelas;
        this.dados.numero_serie = currentData.data.numero_serie;
        this.dados.observacao = currentData.data.observacao;
        this.dados.parcela_final = currentData.data.parcela_final;
        this.dados.pc_acrescimo = currentData.data.pc_acrescimo;
        this.dados.pc_desconto = currentData.data.pc_desconto;
        this.dados.tipo = currentData.data.tipo;
        this.dados.updated_at = currentData.data.updated_at;
        this.dados.valor = currentData.data.valor;
        this.dados.valor_efetivo = currentData.data.valor_efetivo;
        this.dados.valor_original = currentData.data.valor_original;
        this.dados.valor_restante = currentData.data.valor_restante;
        this.dados.status_pagamento = currentData.data.status_pagamento;

        this.showProgresso = false;
      } catch (err) {
        var msgErro = err.response.data.err;
        this.error = msgErro;
      }
    },

    async confirmarPagamento() {
      await this.abirPDF(api.pagamentoConfirmar, this.idregistro, this.dados)
      this.saveCompleted();
    },
    saveCompleted() {
      this.$router.go(this.$router.currentRoute);
    },
    close() {
      this.$router.push({name: this.nome_rota_listagem_dados});
    },
  },
};
</script>

<style scoped>
.text-field-money {
  padding-top: 13px;
  padding-left: 12px;
  height: 66%;
  width: 100%;
  background: #e9eaeb;
  border-bottom: solid 1px gray;
}

.text-field-money-title {
  display: flex;
  position: fixed;
  margin-left: 12px;
  font-size: 13px;
  margin-top: 2px;
}

.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>
@/network