<template>
  <div>
    <visualiza-dados
      :cabecalhos_colunas="cabecalhos_colunas"
      :id_registro="idRegistro"
      :tituloListagem="tituloListagem"
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="colunaOrdenar"
      :pode_imprimir="true"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
      @imprimirItem="imprimirItem"
    />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';

import {api} from '@/network/Api';
import geradorPDF from '@/mixins/geradorPDF';

export default {
  components: {
    VisualizaDados,
  },
  mixins: [geradorPDF],
  data() {
    return {
      tituloListagem: 'Membros',
      cabecalhos_colunas: [
        {
          text: 'Nome',
          value: 'nome',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Endereço',
          value: 'endereco',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Celular',
          value: 'celular',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
      ],
      colunaOrdenar: 'nome',
      idRegistro: 'idpessoa',
      apiConsultarRegistros: api.pessoaListarTodas,
      apiExcluirRegistro: api.pessoaExcluir,
      apiImprimirRegistro: api.pessoaImprimir,
    };
  },
  methods: {
    cadastrarItem() {
      this.$router.push({name: 'PessoaInsert'});
    },
    editarItem(params) {
      this.$router.push({name: 'PessoaEdit', params: {id: params.id}});
    },
    async imprimirItem(params) {
      await this.abirPDF(this.apiImprimirRegistro, params.id);
    },
  },
};
</script>

<style scoped></style>
