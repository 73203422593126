<template>
  <div>
    <visualiza-dados 
      :cabecalhos_colunas="cabecalhos_colunas"
      :id_registro="id_registro"
      :tituloListagem="tituloListagem"
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="coluna_ordenar"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
    />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import { api } from '@/network/Api';

export default {
  components: {
    VisualizaDados
  },
  data() {
    return {
      tituloListagem: 'Lista de formas de pagamento',
      cabecalhos_colunas: [
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ações',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ],
      coluna_ordenar: 'descricao',
      apiConsultarRegistros: api.formaPagamentoListarTodas,
      apiExcluirRegistro: api.formaPagamentoExcluir,
      id_registro: 'idformapagamento',
    }
  },

  methods: {
    cadastrarItem() {
      this.$router.push({ name: 'FormaPagamentoInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'FormaPagamentoEdit', params: { id: params.id } });
    },
  },
};
</script>

<style scoped></style>