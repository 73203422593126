<template>
  <div>
    <v-card v-show="!showProgresso" class="mx-2 my-2" style="background-color: #f8f9fa">
      <v-card-title>
        <v-row class="mx-2" style="padding: 0;">

          <v-col cols="12" xs="12" sm="12" md="9" lg="10" xl="10">
            <h5 style="color: #94896d">{{ tituloListagem }}</h5>
            <v-divider />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
            <v-btn v-show="mostraBotaoCadastrar" block color="#027fc5" dark @click="cadastrar">
              <span style="color: #ffffff">Novo</span>
              <v-icon class="mx-2">mdi-plus</v-icon>
            </v-btn>
          </v-col>

          <v-col class="py-0 my-0" cols="12">
            <slot name="informacoes"></slot>
          </v-col>

          <!--  FILTROS  -->
          <v-col xs="12" sm="12" md="9" lg="10" xl="10">
            <v-row>
              <slot name="filtros"></slot>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field dense v-model="search" prepend-inner-icon="mdi-magnify" label="Digite aqui para pesquisar"
                  clearable solo-inverted hide-details color="#FFFFFF" style="background-color: #f5f4ec" />
              </v-col>
            </v-row>
          </v-col>
          <v-col xs="12" sm="12" md="3" lg="2" xl="2" class="align-self-end">
            <slot name="filtros-buscar">
              <v-btn block elevation="4" color="#990000" dark @click="buscarDados">
                <span style="color: #ffffff">Buscar</span>
                <v-icon class="mx-2">mdi-magnify</v-icon>
              </v-btn>
            </slot>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table 
        :items-per-page="100" 
        :footer-props="{itemsPerPageOptions:[25, 50, 100, 200, -1]}"
        :headers="cabecalhos_colunas"
        :items="registros"
        :search="search"
        :sort-by="coluna_ordenar" 
        fixed-header class="elevation-1"
        :custom-sort="customSort"
        style="background-color:#f8f9fa;overflow-y:auto;max-height:85vh;">
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="column in cabecalhos_colunas" :key="column.value">
              <span v-if="column.value != 'actions'">
                {{ formataColuna(item, column) }}
              </span>
              <div v-if="column.value == 'actions'" class="mx-2" style="text-align: end;">
                <v-tooltip v-if="pode_imprimir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="imprimirItem(item)" small class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                      mdi-printer-outline
                    </v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="editarItem(item)" small class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <!-- Botão para excluir um registro da listagem -->
                <v-tooltip v-if="pode_excluir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="deleteItem(item)" small color="#344767" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>

        <!-- Caixa de diálogo para confirmação da exclusão -->
        <template v-slot:[`top`]>
          <v-dialog v-model="dialogDelete" max-width="555px">
            <v-card>
              <v-card-title class="text-h6">
                Tem certeza que deseja excluir este registro?
              </v-card-title>
              <v-card-actions>
                <v-spacer />

                <v-btn color="#f5f4ec" dark @click="dialogDelete = false">
                  <span style="color: #990000">Não</span>
                </v-btn>

                <v-btn color="#990000" dark @click="deleteItemConfirm">
                  <span style="color: #ffffff">Sim</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde..." />

    <dialog-msg-alerta tituloDialogMsgAlerta="Atenção!" :mensagemAlerta="mensagemAlerta"
      :showMensagem="dialogMensagemAlerta" />

  </div>
</template>

<script>
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import { getApi, excluirApi } from '@/network/ApiService';

export default {
  components: {
    DialogMsgAlerta,
  },

  props: {
    cabecalhos_colunas: {
      type: Array,
      required: true,
    },
    coluna_ordenar: {
      type: String,
      required: false,
    },
    id_registro: {
      type: String,
      required: true,
    },
    tituloListagem: {
      type: String,
      required: false,
    },
    apiConsultarRegistros: {
      type: String,
      required: true,
    },
    apiExcluirRegistro: {
      type: String,
      required: true,
    },
    formataColuna: {
      type: Function,
      default: (item, column) => item[column.value],
    },
    pode_excluir: {
      type: Boolean,
      default: true,
    },
    pode_imprimir: {
      type: Boolean,
      default: false,
    },
    mostraBotaoCadastrar: {
      type: Boolean,
      default: true,
    },
    filtros: {
      type: Object,
      required: false
    },
  },
  created() {
    this.buscarDados();
  },

  data() {
    return {
      dialogMensagemAlerta: false,
      mensagemAlerta: '',
      search: '',
      dialogDelete: false,
      registros: [],
      totalRegistros: 0,
      primarykey: -1,
      showProgresso: false,
    };
  },
  methods: {
    buscarDados() {
      this.showProgresso = true;

      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data;
          this.totalRegistros = this.registros.length;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });
    },
    deleteItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Exclui registro no banco de dados
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch(err => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta = 'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.primarykey = -1;
      this.clearForm();
      this.buscarDados();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.buscarDados();
    },
    cadastrar() {
      this.$emit('cadastrarItem');
    },
    editarItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.$emit('editarItem', { id: this.primarykey });
    },
    imprimirItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.$emit('imprimirItem', { id: this.primarykey, dados: editedItem });
    },
    customSort(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        // Verifica se o valor é vazio ou null
        if (!aValue) return 1;  // Move 'a' para o final se for vazio
        if (!bValue) return -1; // Move 'b' para o final se for vazio

        // Ordena de acordo com o valor da string (normalmente)
        if (aValue < bValue) return sortDesc ? -1 : 1;
        if (aValue > bValue) return sortDesc ? 1 : -1;
        return 0;
      });
    },
  },
};
</script>
