import { render, staticRenderFns } from "./TabelasPrecoView.vue?vue&type=template&id=55496695&scoped=true"
import script from "./TabelasPrecoView.vue?vue&type=script&lang=js"
export * from "./TabelasPrecoView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55496695",
  null
  
)

export default component.exports