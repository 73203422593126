<template>
  <div class="mx-12">
    <v-card v-show="!showProgresso" class="mx-auto my-12" style="background-color: #f8f9fa">
      <v-card-title style="padding:12px 24px; margin-bottom: 45px">
        <v-row>
          <v-col cols="12">
            <v-icon>mdi-menu-open</v-icon>
            <span class="mx-4" style="color: #000">{{ tituloListagem }}</span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row style="padding: 1rem 2rem;">
        <v-col cols="10">
          <slot name="filtros"></slot>
        </v-col>
        <v-col cols="2">
          <v-row align-self="center" style="height: 100%;">
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
              <v-btn block elevation="4" x-large color="#990000" @click="carregarDados">
                <v-icon color="#FFF">mdi-magnify</v-icon>
                <span style="color: #FFF">Buscar</span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <slot name="filtros-acao"></slot> -->
        </v-col>
      </v-row>

      <v-card-title style="margin-bottom: 25px; padding: 12px 24px;">
        <v-row style="display: flex;">
          <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10"> 
            <v-text-field 
              v-model="search"
              style="background-color: #f5f4ec"
              prepend-inner-icon="mdi-magnify"
              label="Pesquisa rápida"
              color="#FFFFFF"
              clearable
              solo-inverted
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
            <v-btn color="#990000" @click="cadastrar" v-show="mostra_botao_cadastrar">
              <v-icon color="#FFF">mdi-pencil-plus-outline</v-icon>
              <span style="color: #ffffff">Novo</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <slot name="topo"></slot>

      <v-data-table
        :items-per-page="100"
        :footer-props="{itemsPerPageOptions:[25, 50, 100, 200, -1]}"
        :headers="cabecalhos_colunas"
        :items="registros"
        :search="search"
        :sort-by="colunaOrdenar"
        style="background-color: #f8f9fa"
        height="45vh"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="column in cabecalhos_colunas" :key="column.value">

              <span v-if="column.type == 'actions'">
                <v-tooltip v-if="tituloListagem == 'Pagamentos' && item" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <template v-if="item.status_pagamento != 'CP'">
                      <v-icon small @click="confirmarPagamento(item)" class="mr-2" color="#11AA11" v-bind="attrs" v-on="on">
                        mdi-currency-usd
                      </v-icon>
                     </template>
                     <template v-else>
                       <v-icon small class="mr-2" color="#11FF22" disabled v-bind="attrs" v-on="on">
                        mdi-currency-usd
                      </v-icon>
                    </template>
                  </template>
                   <span v-if="item.status_pagamento == 'CP'">Pago</span>
                   <span v-else>Marcar como pago</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="editarItem(item)" class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip v-if="pode_excluir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="deleteItem(item)" color="#344767" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </span>
              <span v-else-if="column.type == 'date'">
                {{ formataData(item, column) }}
              </span>
              <span v-else-if="column.type == 'money'">
                {{ formataDinheiro(item, column) }}
              </span>
              <span v-else>
                {{ formataColuna(item, column) }}
              </span>
            </td>
          </tr>
        </template>
 
        <template v-slot:[`top`]>
          <v-dialog v-model="dialogDelete" max-width="555px">
            <v-card>
              <v-card-title class="text-h6">
                Tem certeza que deseja excluir este registro?
              </v-card-title>

              <v-card-actions>
                <v-spacer />

                <v-btn color="#f5f4ec" @click="dialogDelete = false">
                  <span style="color: #990000">Não</span>
                </v-btn>

                <v-btn color="#990000" @click="deleteItemConfirm">
                  <span style="color: #ffffff">Sim</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde" />

    <dialog-msg-alerta
      :mensagemAlerta="mensagemAlerta"
      :showMensagem="dialogMensagemAlerta"
      tituloDialogMsgAlerta="Atenção!"
    >
    </dialog-msg-alerta>
  </div>
</template>

<script>
import formatacaoMixin from '@/mixins/formatacao';
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import {getApi, excluirApi, getApiv2} from '@/network/ApiService';
import geradorPDF from '@/mixins/geradorPDF';
import {api} from '@/network/Api';

export default {
  mixins: [formatacaoMixin, geradorPDF],
  components: {
    DialogMsgAlerta,
  },

  props: {
    colunaOrdenar: {
      type: String,
      required: false,
    },
    id_registro: {
      type: String,
      required: true,
    },
    tituloListagem: {
      type: String,
      required: false,
    },
    apiConsultarRegistros: {
      type: String,
      required: true,
    },
    apiExcluirRegistro: {
      type: String,
      required: true,
    },
    formataColuna: {
      type: Function,
      default: (item, column) => item[column.value],
    },
    pode_excluir: {
      type: Boolean,
      default: true,
    },
    mostra_botao_cadastrar: {
      type: Boolean,
      default: true,
    },
    filtros: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      cabecalhos_colunas: [],
      dialogMensagemAlerta: false,
      mensagemAlerta: '',
      search: '',
      dialogDelete: false,
      registros: [],
      totalRegistros: 0,
      primarykey: -1,
      showProgresso: false,
    };
  },
  methods: {
    async carregarDados() {
      this.showProgresso = true;
      localStorage.setItem(this.tituloListagem, JSON.stringify(this.filtros));
      let res = await getApiv2(this.apiConsultarRegistros, null, this.filtros)
      this.registros = res.dados;
      this.cabecalhos_colunas = res.header;
      this.totalRegistros = this.registros.length;
        
      setTimeout(() => { this.showProgresso = false;}, 100);
    },
    deleteItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch((err) => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta =
              'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.primarykey = -1;
      this.clearForm();
      this.carregarDados();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.carregarDados();
    },
    cadastrar() {
      this.$emit('cadastrarItem');
    },
    editarItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.$emit('editarItem', {id: this.primarykey});
    },
    confirmarPagamento(item) {
      this.abirPDF(api.pagamentoConfirmar, item.idpagamento, item);
      this.carregarDados();
    },
  },
};
</script>