<template>
  <div>
    <visualiza-dados 
      :cabecalhos_colunas="cabecalhoColunas" 
      :id_registro="idregistro"
      :tituloListagem="tituloListagem" 
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="colunaOrdenar" 
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
    />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import {api} from '@/network/Api';

export default {
  components: {
    VisualizaDados
  },
  data() {
    return {
      tituloListagem: 'Lista de perfis',
      colunaOrdenar: 'descricao',
      idregistro: 'idperfil',
      apiExcluirRegistro: api.perfilExcluir,
      apiConsultarRegistros: api.perfilListarTodos,
      
      cabecalhoColunas: [
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
    }
  },
  methods: {
    cadastrarItem() {
      this.$router.push({name: 'PerfilInsert'});
    },
    editarItem(params) {
      this.$router.push({name: 'PerfilEdit', params: { id: params.id } });
    }
  }
}
</script>

<style scoped></style>