<template>
  <div>
    <relatorio-view :tituloRelatorio="tituloRelatorio" :showProgresso="showProgresso">
      <template v-slot:filtros>
        <v-row>
          <v-col cols="12">
            <v-select v-model="filtros.somenteAtivos" :items="statusList" item-value="valor" item-text="descricao" label="Somente Ativos" color="blue" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:filtros-buscar>
        <v-row justify="center" align-self="center" no-gutters style="height: 100%;">
          <v-col cols="12" sm="auto" md="auto" align-self="center">
            <v-btn block elevation="4" x-large color="#990000" dark @click="buscarDados">
              <span style="color: #ffffff">Buscar</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:dados>
        <v-data-table 
          :items-per-page="100" 
          :footer-props="{itemsPerPageOptions:[25, 50, 100, 200, -1]}"
          :headers="cabecalhos_colunas"
          :items="registros" 
          :search="search" 
          :sort-by="coluna_ordenar"
          style="background-color: #f8f9fa"
          height="65vh"
          fixed-header
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn @click="imprimir()">
                <v-icon>mdi-printer-outline</v-icon>
                Imprimir
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="column in cabecalhos_colunas" :key="column.value">
                <span v-if="column.value == 'status'">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </span>
                <span v-else-if="column.value == 'data_nascimento'">
                  {{ formataData(item.data_nascimento) }}
                </span>
                <!-- <span v-else-if="column.value == 'actions'">
                  item
                </span> -->
                <span v-else>
                  {{ formataColuna(item, column) }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </relatorio-view>
  </div>
</template>

<script>
import formatacaoMixin from '@/mixins/formatacao';
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  mixins: [formatacaoMixin],
  components: {
    DialogMsgAlerta,
    RelatorioView,
  },
  created() {
    this.initialize();
  },

  data() {
    return {
      dialogMensagemAlerta: false,
      mensagemAlerta: '',
      showProgresso: false,
      registros: [],
      search: '',
      tituloRelatorio: "Relatório de membros por faixa",
      coluna_ordenar: '',
      statusList: [{ valor: true, descricao: 'Sim' }, { valor: false, descricao: 'Não' },],
      cabecalhos_colunas: [],
      apiConsultarRegistros: api.relatorioMembrosPorFaixa,
      apiImprimirRegistros: api.relatorioMembrosPorFaixaImprimir,
      filtros: {
        somenteAtivos: true,
      },
    };
  },
  methods: {
    initialize() {
      this.buscarDados();
    },
    buscarDados() {
      this.showProgresso = true;
      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = res.data.header;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
    },
    formataColuna(item, column) {
      return item[column.value];
    },
   
    async imprimir() {
      console.log("iniciando download");
      let nomeArquivo = "relatorio";
      try {
        const response = await getApi(this.apiImprimirRegistros, null, this.filtros, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const base64 = response.data;
          const filename = `${nomeArquivo}.pdf`;
          // Converte a string Base64 para um array de bytes
          const byteCharacters = window.atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Cria um objeto Blob a partir do array de bytes
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Cria uma URL temporária para o Blob e inicia o download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          console.error(`Erro ao baixar o PDF. Status: ${response.status}`);
        }
      } catch (error) {
        console.error(`Falha ao baixar o PDF: ${error.message}`);
      }
    },
  },
};
</script>
