export const api = {
  login: 'api/autenticacao/login',
  logout: 'api/autenticacao/usuario/logout',

  // Acesso aos PERFIS
  perfilConsultar: 'api/perfil/atual',
  perfilListarTodos: 'api/perfil/todos',
  perfilCriar: 'api/perfil/criar',
  perfilAtualizar: 'api/perfil/atualizar',
  perfilExcluir: 'api/perfil/excluir',

  // HABILIDADES
  habilidadesConsultar: 'api/ability/todas',

  //  Acesso aos Usuários
  usuarioConsultar: 'api/usuarios/atual',
  usuarioListarTodos: 'api/usuarios/todos',
  usuarioCriar: 'api/usuarios/criar',
  usuarioAtualizar: 'api/usuarios/atualizar',
  usuarioExcluir: 'api/usuarios/excluir',

  //  Acesso a Comunidade
  comunidadeConsultar: 'api/comunidade/atual',
  comunidadeListarTodas: 'api/comunidade/todas',
  comunidadeCriar: 'api/comunidade/criar',
  comunidadeAtualizar: 'api/comunidade/atualizar',
  comunidadeExcluir: 'api/comunidade/excluir',

  // Acesso as Faixas de Contrato
  faixaContratoConsultar: 'api/faixacontrato/atual',
  faixaContratoListarTodas: 'api/faixacontrato/todos',
  faixaContratoCriar: 'api/faixacontrato/criar',
  faixaContratoAtualizar: 'api/faixacontrato/atualizar',
  faixaContratoExcluir: 'api/faixacontrato/excluir',

  // Acesso as Contas
  contaConsultar: 'api/conta/atual',
  contaListarTodas: 'api/conta/todas',
  contaCriar: 'api/conta/criar',
  contaAtualizar: 'api/conta/atualizar',
  contaExcluir: 'api/conta/excluir',

  // Acesso as Caixas
  caixaConsultar: 'api/caixa/atual',
  caixaListarTodas: 'api/caixa/todos',
  caixaCriar: 'api/caixa/criar',
  caixaAtualizar: 'api/caixa/atualizar',
  caixaExcluir: 'api/caixa/excluir',

  // Acesso as Formas de Pagamento
  formaPagamentoConsultar: 'api/formapagamento/atual',
  formaPagamentoListarTodas: 'api/formapagamento/todas',
  formaPagamentoCriar: 'api/formapagamento/criar',
  formaPagamentoAtualizar: 'api/formapagamento/atualizar',
  formaPagamentoExcluir: 'api/formapagamento/excluir',

  // Acesso as Pessoa
  pessoaConsultar: 'api/pessoa/atual',
  pessoaListarTodas: 'api/pessoa/todas',
  pessoaCriar: 'api/pessoa/criar',
  pessoaAtualizar: 'api/pessoa/atualizar',
  pessoaExcluir: 'api/pessoa/excluir',
  pessoaImprimir: 'api/pessoa/imprimir',

  // Acesso aos Contratos
  contratoConsultar: 'api/contrato/atual',
  contratoListarTodos: 'api/contrato/todos',
  contratoCriar: 'api/contrato/criar',
  contratoAtualizar: 'api/contrato/atualizar',
  contratoExcluir: 'api/contrato/excluir',
  contratoImprimir: 'api/contrato/imprimir',
  contratoRenovar: 'api/contrato/renovar',
  contratoStatus: 'api/contrato/status',
  contratoCancelar: 'api/contrato/cancelar',

  observacaoContrato: 'api/contrato/observacao-contrato',
  // Acesso ao historio de pagamentos dos Contratos
  contratoHistoricoAtual: 'api/contrato/historico/atual',
  contratoHistoricoRelatorio: 'api/contrato/historico/relatorio',
  cancelarParcelasContrato: 'api/contrato/cancelar-parcelas/atual',
  // Acesso as transferencias
  transferenciaConsultar: 'api/transferencias/atual',
  transferenciaListarTodas: 'api/transferencias/todas',
  transferenciaListarTodasImprimir: 'api/transferencias/todas/imprimir',
  transferenciaCriar: 'api/transferencias/criar',
  transferenciaAtualizar: 'api/transferencias/atualizar',
  transferenciaExcluir: 'api/transferencias/excluir',

  // Acesso a Tabela de Preços
  faixaValorConsultar: 'api/faixavalor/atual',
  faixaValorListarTodos: 'api/faixavalor/todos',
  faixaValorCriar: 'api/faixavalor/criar',
  faixaValorAtualizar: 'api/faixavalor/atualizar',
  faixaValorExcluir: 'api/faixavalor/excluir',
  faixaValorConsultarVariacao: 'api/faixavalor/anual',
  faixaValorConsultarValor: 'api/faixavalor/consultar-valor',

  // Acesso a Pssoa X Relação
  pessoaRelacaoConsultar: 'api/pessoa/relacao/atual',
  pessoaRelacaoListarTodas: 'api/relacao/todas',
  pessoaRelacaoEditar: 'api/pessoa/relacao/editar',
  pessoaRelacaoCriar: 'api/pessoa/relacao/criar',
  pessoaRelacaoExcluir: 'api/pessoa/relacao/excluir',

  // Acesso a Pessoa X Relação Financeiro > Pagamento
  pagamentoConsultar: 'api/pagamento/atual',
  pagamentoListarTodos: 'api/pagamento/todos',
  pagamentoListarTodosImprimir: 'api/pagamento/todos/imprimir',
  pagamentoCriar: 'api/pagamento/criar',
  pagamentoEditar: 'api/pagamento/atualizar',
  pagamentoConfirmar: 'api/pagamento/confirmar',
  pagamentoLoteEditar: 'api/pagamento/atualizar-lote',
  pagamentoExcluir: 'api/pagamento/excluir',
  pagamentoImprimir: 'api/pagamento/imprimir',

  // Relatórios
  relatorioAniversarios: 'api/relatorios/aniversarios',
  relatorioFaixaEtaria: 'api/relatorios/faixaetaria',
  relatorioBatismos: 'api/relatorios/batismos',
  relatorioConfirmacoes: 'api/relatorios/confirmacoes',
  relatorioInadimplentes: 'api/relatorios/inadimplentes',
  relatorioMembrosPorFaixa: 'api/relatorios/membros-por-faixa',

  relatorioAniversariosImprimir: 'api/relatorios/aniversarios/imprimir',
  relatorioFaixaEtariaImprimir: 'api/relatorios/faixaetaria/imprimir',
  relatorioBatismosImprimir: 'api/relatorios/batismos/imprimir',
  relatorioConfirmacoesImprimir: 'api/relatorios/confirmacoes/imprimir',
  relatorioInadimplentesImprimir: 'api/relatorios/inadimplentes/imprimir',
  relatorioMembrosPorFaixaImprimir: 'api/relatorios/membros-por-faixa/imprimir',
  livroCaixa: 'api/relatorios/contas/livro-caixa',
  livroCaixaDetalhado: 'api/relatorios/contas/livro-caixa-detalhado',

  consultarCep: 'api/endereco/buscar',
};
