<template>
  <div>
    <visualiza-dados-listagem
      :id_registro="id_registro"
      :tituloListagem="tituloListagem"
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="coluna_ordenar"
      :mostra_botao_cadastrar="mostra_botao_cadastrar"
      :filtros="filtros"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem"
    >
      <template v-slot:filtros>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <CampoData titulo="Início do intervalo" v-model="filtros.dataInicio" />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <CampoData titulo="Fim do intervalo" v-model="filtros.dataFim" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12" sm="6" md="6">
            <v-select
              v-model="filtros.idContaOrigem"
              :items="listaContas"
              color="blue"
              item-text="descricao"
              item-value="idconta"
              label="Conta Origem"
              hide-details
              persistent-hint
              clearable
            />
          </v-col>
          <v-col
            cols="12" sm="6" md="6">
            <v-select
              v-model="filtros.idContaDestino"
              :items="listaContas"
              color="blue"
              item-text="descricao"
              item-value="idconta"
              label="Conta destino"
              hide-details
              persistent-hint
              clearable
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:topo>
        <v-toolbar flat>
          <v-spacer />
          <v-btn @click="imprimir()">
            <v-icon>mdi-printer-outline</v-icon>
            Imprimir
          </v-btn>
        </v-toolbar>
      </template>
    </visualiza-dados-listagem>
  </div>
</template>

<script>
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import visualizaDadosListagem from '@/components/crud/VisualizaDadosListagem.vue';
import {api} from '@/network/Api';
import {getApi} from '@/network/ApiService';
import {format, parseISO} from 'date-fns';
import mixin from '@/store/mixin.js';
import geradorPDF from '@/mixins/geradorPDF';
import network from '@/mixins/network';

export default {
  mixins: [mixin, geradorPDF, network],
  components: {
    RelatorioView,
    visualizaDadosListagem,
  },
  data() {
    return {
      tituloListagem: 'Transferências entre contas',
      search: '',
      coluna_ordenar: 'data_movimentacao',
      apiContas: api.contaListarTodas,
      listaContas: [],
      cabecalhos_colunas: [],
      apiConsultarRegistros: api.transferenciaListarTodas,
      apiImprimirRegistros: api.transferenciaListarTodasImprimir,
      apiExcluirRegistro: api.transferenciaExcluir,
      id_registro: 'idtransferencia',
      mostra_botao_cadastrar: true,

      filtros: {
        dataInicio: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        dataFim: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        idContaOrigem: '',
        idContaDestino: '',
      },
    };
  },
  created() {
    this.buscarFiltros();
    this.initialize();
  },
  methods: {
    buscarFiltros(){
      let filtrosSalvos = localStorage.getItem(this.tituloListagem);
      if(filtrosSalvos) {
        this.filtros = JSON.parse(filtrosSalvos);
      }
    },
    initialize() {
      this.buscarContas();
    },
    buscarContas() {
      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formataColuna(item, column) {
      return item[column.value];
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.primarykey = this.editedItem[this.id_registro];
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch((err) => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta =
              'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.primarykey = -1;
      this.clearForm();
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.initialize();
      this.mostra_botao_cadastrar = true;
    },
    cadastrarItem() {
      this.$router.push({name: 'TransferenciaInsert'});
    },
    editarItem(params) {
      this.$router.push({name: 'TransferenciaEdit', params: {id: params.id}});
    },
    async imprimir() {
      await this.abirPDFv2(this.apiImprimirRegistros, null, this.filtros);
    },
  },
};
</script>

<style scoped></style>
