<template>
  <v-container style="padding: 0px;">
    <header v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md" style="background-color: blue;">
      <menu-mobile :items="itensMenu" :img_logo="require('@/assets/img/logo_ieclb_mobile.png')" style="cursor: pointer;"></menu-mobile>
    </header>
    <main>
      <div class="menu_pagina" v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
        <menu-web :items="itensMenu" :img_logo="require('@/assets/img/logo_ieclb_mobile.png')" style="cursor: pointer;"></menu-web>
      </div>

      <v-container fluid fill-height class="box corpo_pagina">
        <v-row align="center" justify="center">
          <v-col>
            <router-view style="overflow: hidden;" />
          </v-col>
        </v-row>
      </v-container>
    </main>

    <footer class="box rodape_pagina">
      <footer-page :texto_rodape="'Igreja Evangélica de Confissão Luterana'"></footer-page>
    </footer>
  </v-container>
</template>

<script>
import MenuWeb from '../components/sidemenu/MenuWeb.vue'
import MenuMobile from '../components/sidemenu/MenuMobile.vue'
import FooterPage from '../components/footer/FooterPage.vue'
import listaMenus from "@/libs/menus";

export default {
  name: 'HomeView',
  components: {
    MenuWeb,
    MenuMobile,
    FooterPage
  },
  created() {
    var larguraDaTela = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // alert(larguraDaTela);
  },
  data() {
    return {
      itensMenu: listaMenus
    }
  },
}
</script>

<style>
main {
  display: flex;
  justify-content: space-between;
}

.box {
  flex: 1;
  /* Cresce para preencher o espaço disponível igualmente */
}

.menu_pagina {
  height: 95vh;
}

.corpo_pagina {
  margin-top: 0px;
}

/* Estilos para dispositivos móveis */
@media only screen and (max-width: 1264px) {
  .corpo_pagina {
    margin-top: 40px;
  }
}

.rodape_pagina {
  background-color: aqua;
}
</style>