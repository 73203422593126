<template>
  <div>
    <template v-if="!showProgresso">
    <insere-dados 
      :titulo_formulario="titulo_formulario"
      :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="array_campos_formulario"
      @saveCompleted="saveCompleted"
      @close="close"
    >
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="array_campos_formulario[0].valor"
            :items="listPessoas"
            :rules="titularRules"
            label="Titular do contrato"
            item-value="idpessoa"
            item-text="nome"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            :rules="faixaRules"
            :items="listFaixas"
            v-model="array_campos_formulario[1].valor"
            label="Faixa do contrato"
            item-value="idfaixacontrato"
            item-text="descricao"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="array_campos_formulario[3].valor"
            :items="listaContas"
            label="Conta padrão"
            item-value="idconta"
            item-text="descricao"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-select
            label="Forma de pagamento padrão"
            v-model="array_campos_formulario[4].valor"
            :items="listaFormasPagamento"
            item-value="idformapagamento"
            item-text="descricao"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            label="Utiliza cobrador"
            v-model="array_campos_formulario[5].valor"
            color="blue"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="array_campos_formulario[2].valor"
            :items="listaStatus"
            :rules="statusRules"
            label="Status"
            item-text="descricao"
            item-value="valor"
            color="#94896d"
            dense
          />
        </v-col>
        <v-col cols="4">
          <DialogData
            titulo="Data de assinatura"
            v-model="array_campos_formulario[6].valor"
            :rules="dataAssinaturaRules"
          />
        </v-col>
        <v-col cols="4">
          <DialogData
            titulo="Data de desligamento (opcional)"
            v-model="array_campos_formulario[7].valor"
          />
        </v-col>
      </v-row>
    </insere-dados>
    </template>
    <template v-else>
      <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde" />
    </template>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import InsereDados from '@/components/crud/InsereDados.vue';
import {api} from '@/network/Api';
import DialogData from '@/components/dialogs/DialogData.vue';
import {getApiv2} from '@/network/ApiService';

export default {
  mixins: [mixin],
  components: {
    InsereDados,
    DialogData,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      titulo_formulario: 'Novo contrato',
      apiInserirRegistro: api.contratoCriar,
      array_campos_formulario: [
        {
          valor: '',
          nome_campo: 'idpessoa_titular',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idfaixacontrato',
          obrigatorio: false,
        },
        {
          valor: 1,
          nome_campo: 'status',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idconta',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idformapagamento_padrao',
          obrigatorio: true,
        },
        {
          valor: false,
          nome_campo: 'utiliza_cobrador',
          obrigatorio: true,
        },
        {
          valor: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          nome_campo: 'data_assinatura',
          obrigatorio: true,
        },
        {
          valor: null,
          nome_campo: 'data_desligamento',
          obrigatorio: false,
        },
      ],
      showProgresso: false,
      listaContas: [],
      listaFormasPagamento: [],
      listFaixas: [],
      listPessoas: [],
      listaStatus: [
        {valor: 1, descricao: 'Ativo'},
        {valor: 2, descricao: 'Inativo'},
        {valor: 3, descricao: 'Desligado'},
      ],
    };
  },

  computed: {
    faixaRules() {
      return [(v) => !!v || 'Selecione a faixa do contrato'];
    },
    statusRules() {
      return [(v) => !!v || 'Informe o status do contrato'];
    },
    titularRules() {
      return [(v) => !!v || 'Selecione o titular do contrato'];
    },
    dataAssinaturaRules() {
      return [(v) => !!v || 'Informe a data de assinatura do contrato'];
    },
  },
  methods: {
    async initialize() {
      this.showProgresso = true;
      this.listaContas = await getApiv2(api.contaListarTodas);
      this.listaFormasPagamento = await getApiv2(api.formaPagamentoListarTodas);
      this.listFaixas = await getApiv2(api.faixaContratoListarTodas);
      this.listPessoas = await getApiv2(api.pessoaListarTodas);
      this.showProgresso = false;
    },

    saveCompleted(item) {
      this.$router.push({name: 'Contratos'});
    },
    close() {
      this.$router.push({name: 'Contratos'});
    },
  },
};
</script>